<template>
    <div>
        <b-overlay :show="loading">
            <b-row>
                
                <b-col cols="12" md="6">
                    <b-card title="Preferencias del Sitio">
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Nombre del Sitio" label-for="nombre"> 
                                    <b-form-input
                                        id="nombre"
                                        v-model="nombre_sitio"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Dirección" label-for="direccion"> 
                                    <b-form-input
                                        id="direccion"
                                        v-model="direccion"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Teléfono" label-for="telefono"> 
                                    <b-form-input
                                        id="telefono"
                                        v-model="telefono"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Fax" label-for="fax"> 
                                    <b-form-input
                                        id="fax"
                                        v-model="fax"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Horario de Atención" label-for="horario"> 
                                    <b-form-input
                                        id="horario"
                                        v-model="horario"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Línea Gratuita" label-for="linea_gratuita"> 
                                    <b-form-input
                                        id="linea_gratuita"
                                        v-model="linea_gratuita"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-checkbox
                                    id="chat"
                                    v-model="chat_facebook"
                                    value="1"
                                    unchecked-value="0"
                                    switch
                                >
                                    <b>Activar Chat Facebook</b>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-1 text-right">
                                <b-button                                   
                                    variant="primary"
                                    @click="guardarPreferenciasSitio()"
                                >
                                    <span class="text-nowrap">Guardar</span>
                                </b-button>
                            </b-col>                            
                        </b-row>
                    </b-card>
                </b-col>
                
                <b-col cols="12" md="6">
                    <b-card title="Redes Sociales">
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Facebook" label-for="facebook"> 
                                    <b-form-input
                                        id="facebook"
                                        v-model="facebook"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Twitter" label-for="twitter"> 
                                    <b-form-input
                                        id="twitter"
                                        v-model="twitter"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="YouTube" label-for="youtube"> 
                                    <b-form-input
                                        id="youtube"
                                        v-model="youtube"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Instagram" label-for="instagram"> 
                                    <b-form-input
                                        id="instagram"
                                        v-model="instagram"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-1 text-right">
                                <b-button                                   
                                    variant="primary"
                                    @click="guardarPreferenciasRedesSociales()"
                                >
                                    <span class="text-nowrap">Guardar</span>
                                </b-button>
                            </b-col>                            
                        </b-row>
                    </b-card>
                </b-col>
                <!-- <b-col cols="12" md="6">
                    <b-card title="Preferencias Moodle">
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Url Moodle" label-for="url"> 
                                    <b-form-input
                                        id="url"
                                        v-model="url"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Usuario" label-for="usuario"> 
                                    <b-form-input
                                        id="usuario"
                                        v-model="usuario"                                        
                                    />                               
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Contraseña" label-for="contrasenia"> 
                                    <b-form-input
                                        type="password"
                                        id="contrasenia"
                                        v-model="contrasenia"                                        
                                    />                               
                                </b-form-group>
                            </b-col>                            
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-1 text-right">
                                <b-button                                   
                                    variant="primary"
                                >
                                    <span class="text-nowrap">Guardar</span>
                                </b-button>
                            </b-col>                            
                        </b-row>
                    </b-card>
                </b-col> -->
            </b-row>
            <ImagenComponent
              @enviarImagen="recibirImagen"
            ></ImagenComponent>
        </b-overlay>
    </div>
</template>
<script>
import axiosIns from '@/libs/axios'
import {BFormCheckbox, BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BImg, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BMedia, BMediaAside, BMediaBody, BTable, BInputGroup, BPagination, BOverlay, BInputGroupPrepend, BInputGroupAppend} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

import { computed } from '@vue/composition-api';
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import Verte from "verte";
import "verte/dist/verte.css";
import { Chrome } from "vue-color";

// import store from '@/store'
export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BImg, 
    BDropdown, BDropdownItem,
    BListGroup, BListGroupItem,
    
    BMedia, BMediaAside, BMediaBody,
    BTable,
    BInputGroup,
    BPagination,
    
    BOverlay,
    BInputGroupPrepend, BInputGroupAppend,
    ImagenComponent,
    Verte,
    Chrome,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data () {
      return {
        errores:[],
        items: [],
        key:'',
        
        submitted: false,
        loading: false,
        id:'',
        status:false,
        
        id_preferencias_disenio: 0,
        imagen_white: '',
        selectImgW: false,
        imagen_dark: '',
        selectImgD: false,
        opciones: {        
            colorpri: "#000000", // F48C06           
            colorsec: "#000000", //1B73BB
            colorbg: "#FFFFFF",
        },

        id_preferencias_sitio: 0,
        nombre_sitio: 'Alianza Libres de Violencia',
        direccion: 'Calle ',
        telefono: '+591 ',
        fax: '+591 ',
        horario: 'Lunes a Viernes de 8:00 a 12:00 y de 14:30 a 18:30',
        linea_gratuita: '-',
        chat_facebook: 0,
        
        id_preferencias_fiscaliatv: 0,
        imagen_titulo_podcasts:'',
        selectImgP: false,
        imagen_titulo_fiscaliatv:'',
        selectImgF: false,
        imagen_logo_fiscaliatv: '',
        selectImgLF: false,        
        imagen_fondo_fiscaliatv: '',
        selectImgFF: false,

        id_preferencias_rs: 0,
        facebook: 'https://www.facebook.com/alsv/',
        twitter:'https://twitter.com/alsv',
        instagram: 'https://www.instagram.com/alsv',
        youtube: 'https://www.youtube.com/c/alsv',

      }
  },
  computed:{

  },
  created(){    
    //this.listarVideos();
    // this.getPreferenciasDisenio();
    this.getPreferenciasSitio();    
    // this.getPreferenciasFiscaliaTV();    
    this.getPreferenciasRedesSociales();    
  },
  watch: {
    channelId: function(val) {

    },
    key: function(val) {

    },
    status: function(val) {
        if (val) {
            this.sincronizarVideos();
        }
    },
  },
  methods: {
    async mensaje(mensaje, tipo) {
      await this.$swal({
        title: mensaje,
        icon: tipo,
        timer: 3000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getPreferenciasDisenio(){
        const api = this.mixing.opcions.find((g) => g.nombre === "preferencias_disenio");
        if (api.id) {
            axiosIns
            .get("opciones/" + api.id)
            .then((res) => {
                const preferencias = JSON.parse(res.data.data.valor);

                this.id_preferencias_disenio = api.id;
                this.imagen_white = preferencias.datos.logo_white;
                this.imagen_dark = preferencias.datos.logo_dark;
                this.opciones.colorpri = preferencias.datos.color_primario;
                this.opciones.colorsec = preferencias.datos.color_secundario;
                this.opciones.colorbg = preferencias.datos.color_fondo;
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },
    async guardarPreferenciasDisenio() {
        const valor = `{"datos":{"logo_white":"${this.imagen_white}", "logo_dark":"${this.imagen_dark}", "color_primario":"${this.opciones.colorpri}", "color_secundario":"${this.opciones.colorsec}", "color_fondo":"${this.opciones.colorbg}" }}`;
        axiosIns
                .put(`/opciones/${this.id_preferencias_disenio}`, 
                    {
                        'nombre': 'preferencias_disenio',
                        'valor' : valor
                    })
                .then((response) => {
                    this.mensaje(
                        "Actualizado con éxito",
                        "success"
                    );
                })
                .catch((error) => {
                    this.mensaje(
                        "Ocurrió un error",
                        "error"
                    );
                });
    },
    getPreferenciasSitio(){
        const api = this.mixing.opcions.find((g) => g.nombre === "preferencias_sitio");
        if (api.id) {
            axiosIns
            .get("opciones/" + api.id)
            .then((res) => {
                const preferencias = JSON.parse(res.data.data.valor);

                this.id_preferencias_sitio = api.id;
                this.nombre_sitio = preferencias.datos.nombre_sitio;
                this.direccion = preferencias.datos.direccion;
                this.telefono = preferencias.datos.telefono;
                this.fax = preferencias.datos.fax;
                this.horario = preferencias.datos.horario;
                this.linea_gratuita = preferencias.datos.linea_gratuita;
                this.chat_facebook = preferencias.datos.chat_facebook;
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },
    async guardarPreferenciasSitio() {
        const valor = `{"datos":{"nombre_sitio":"${this.nombre_sitio}", "direccion":"${this.direccion}", "telefono":"${this.telefono}", "fax":"${this.fax}", "horario":"${this.horario}", "linea_gratuita":"${this.linea_gratuita}", "chat_facebook":"${this.chat_facebook}"}}`;
        axiosIns
                .put(`/opciones/${this.id_preferencias_sitio}`, 
                    {
                        'nombre': 'preferencias_sitio',
                        'valor' : valor
                    })
                .then((response) => {
                    this.mensaje(
                        "Actualizado con éxito",
                        "success"
                    );
                })
                .catch((error) => {
                    this.mensaje(
                        "Ocurrió un error",
                        "error"
                    );
                });
    },
    getPreferenciasFiscaliaTV(){
        const api = this.mixing.opcions.find((g) => g.nombre === "preferencias_fiscaliatv");
        if (api.id) {
            axiosIns
            .get("opciones/" + api.id)
            .then((res) => {
                const preferencias = JSON.parse(res.data.data.valor);

                this.id_preferencias_fiscaliatv = api.id;
                this.imagen_titulo_podcasts = preferencias.datos.imagen_titulo_podcasts;
                this.imagen_titulo_fiscaliatv = preferencias.datos.imagen_titulo_fiscaliatv;
                this.imagen_logo_fiscaliatv = preferencias.datos.logo_fiscaliatv;
                this.imagen_fondo_fiscaliatv = preferencias.datos.fondo_fiscaliatv;
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },
    async guardarPreferenciasFiscaliaTV() {
        const valor = `{"datos":{"imagen_titulo_podcasts":"${this.imagen_titulo_podcasts}", "imagen_titulo_fiscaliatv":"${this.imagen_titulo_fiscaliatv}", "logo_fiscaliatv":"${this.imagen_logo_fiscaliatv}", "fondo_fiscaliatv":"${this.imagen_fondo_fiscaliatv}"}}`;
        axiosIns
                .put(`/opciones/${this.id_preferencias_fiscaliatv}`, 
                    {
                        'nombre': 'preferencias_fiscaliatv',
                        'valor' : valor
                    })
                .then((response) => {
                    this.mensaje(
                        "Actualizado con éxito",
                        "success"
                    );
                })
                .catch((error) => {
                    this.mensaje(
                        "Ocurrió un error",
                        "error"
                    );
                });
    },
    getPreferenciasRedesSociales(){
        const api = this.mixing.opcions.find((g) => g.nombre === "redes_sociales");
        if (api.id) {
            axiosIns
            .get("opciones/" + api.id)
            .then((res) => {
                const preferencias = JSON.parse(res.data.data.valor);

                this.id_preferencias_rs = api.id;
                this.facebook = preferencias.datos.facebook;
                this.twitter = preferencias.datos.twitter;
                this.instagram = preferencias.datos.instagram;
                this.youtube = preferencias.datos.youtube;
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },
    async guardarPreferenciasRedesSociales() {
        const valor = `{"datos":{"facebook":"${this.facebook}", "twitter":"${this.twitter}", "instagram":"${this.instagram}", "youtube":"${this.youtube}"}}`;
        axiosIns
                .put(`/opciones/${this.id_preferencias_rs}`, 
                    {
                        'nombre': 'redes_sociales',
                        'valor' : valor
                    })
                .then((response) => {
                    this.mensaje(
                        "Actualizado con éxito",
                        "success"
                    );
                })
                .catch((error) => {
                    this.mensaje(
                        "Ocurrió un error",
                        "error"
                    );
                });
    },

    recibirImagen(imagen) {
      if(this.selectImgW){
          this.imagen_white = imagen.url;
          this.selectImgW = false;
      }

      if(this.selectImgD){
          this.imagen_dark = imagen.url;
          this.selectImgD = false;
      }  

      if(this.selectImgP){
          this.imagen_titulo_podcasts = imagen.url;
          this.selectImgP = false;
      }  

      if(this.selectImgF){
          this.imagen_titulo_fiscaliatv = imagen.url;
          this.selectImgF = false;
      } 

      if(this.selectImgLF){
          this.imagen_logo_fiscaliatv = imagen.url;
          this.selectImgLF = false;
      }    

      if(this.selectImgFF){
          this.imagen_fondo_fiscaliatv = imagen.url;
          this.selectImgFF = false;
      }
    },
  },
}
</script>
<style lang="scss">
    .box-imagen {
    height: 14rem;
    }
    .box-imagen img {
    width: 100%;
    height: 100%;
    }
    .box-imagen1 img {
    object-fit: contain;
    }
    .vs__open-indicator {
    fill: rgba(60, 60, 60, 0.5);
    }
</style>